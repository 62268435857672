.guide-container {
  padding-left: 4vw;
  padding-right: 4vw;
  // padding-top: 4vh;
  padding-bottom: 25px;

  h2 {
    padding-left: 0.8%;
    padding-right: 0.8%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.75rem;
    color: black;
    font-weight: 800;
  }

  .features-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    overflow: hidden;
    // z-index: 100;
  }
  
  
  
}


