.tables-container {
    // display: block;
    width: 100%;
    flex-wrap: wrap;
    // overflow: hidden;
    padding-left: 4vw;
    padding-right: 4vw;
    
    .table-container {
        margin-bottom: 5%;
    }

    // .t1 {
    //     width: 25%;
    // }

    // .t2 {
    //     width: 25%;
    // }
}
