.stats-container {
    padding-left: 4vw;
    padding-right: 4vw;

    .queries-container {

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .button-container {

            margin-left: 20px;
            margin-bottom: 10px;



                .loader-container {
                    width: 100%;
                    height: 100vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: fixed;
                    background: rgba(0, 0, 0, 0.834);
                    z-index: 9999;
                }
                
                .spinner {
                    width: 64px;
                    height: 64px;
                    border: 8px solid;
                    border-color: #3d5af1 transparent #3d5af1 transparent;
                    border-radius: 50%;
                    animation: spin-anim 1.2s linear infinite;
                }
                
                @keyframes spin-anim {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }





            .button {
                cursor: pointer;
                background-color: #008CBA; /* Green */
                border: none;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;


                .spinner {
                    animation-name: spin;
                    animation-duration: 500ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                  }
                  
                  @keyframes spin {
                    from {
                      transform: rotate(0deg);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                  }

              }
        }


        .query-fields {
            margin-bottom: 50px;
    
            .search-bars-container {
                min-width: 200px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                // justify-content: space-around;
                align-content: center;
                overflow: hidden;

                .search-bar-container {
                    float: left;
                    width: 325px;
                    // padding-bottom: 125px;

                    .select {
                        padding-bottom: 10px;
                    }

                    h3 {
                        padding: 0;
                        margin-bottom: 0.4rem;
                        font-weight: 575;
                        color: black;
                    }
                    
                }

                .player-name {
                    padding-left: 0.5%;
                    padding-right: 0.5%;
                    width: 360px;
                }

                .start-year {
                    padding-left: 1.6%;
                    padding-right: 1.6%;
                    width: 180px;
                }

                .end-year {
                    padding-left: 1.6%;
                    padding-right: 1.6%;
                    width: 180px;
                }

                .min-def-rtg {
                    padding-left: 1.6%;
                    padding-right: 1.6%;
                    width: 180px;
                }

                .max-def-rtg {
                    padding-left: 1.6%;
                    padding-right: 1.6%;
                    width: 180px;
                }
    
            }
        }
    
        .stat-tables {
            padding-left: 0.8%;
            padding-right: 0.8%;
    
            h2 {
                color: black;
            }
        }



    }

}