.guide-item-container {
    // min-width: 100px;
    // width: 30%;
    // min-width: 15%;
    height: 280px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 0.8%;
    padding-right: 0.8%;
    // margin: 0 7.5px 15px;
    overflow: hidden;
  
    // &:hover {
    //   & .background-image {
    //     transform: scale(1.2);
    //     transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    //   }
    // }
  
    &.large {
      height: 380px;
    }
  


    
  
    .guide-item-body {
      // height: 90px;
      // max-width: 90%;
      margin-top: 5px;
      text-align: start;
      // padding: 0px 25px;
    //   border: 1px solid black;
    //   background-color: white;
      // border-radius: 10px;
    //   opacity: 0.9;
      // position: b;

      &.Magic {
        top: 0px;
        left: 0px;
      }

      &.LeBron {
        top: 0px;
        left: 0px;
      }

      &.Steph {
        top: 0px;
        left: 0px;
      }


  
      h3 {
        font-size: 20px;
        // margin: 0 7.5px 15px;
        // font-size: 100px;
        color: black;
        font-weight: 600;
      }
  
      p {
        font-weight: lighter;
        font-size: 16px;
      }
    }

    .image-container {
      display: flex;
      // justify-content: center;
      height: 240px;
      width: 100%;
      overflow: hidden;
      // border-radius: 10px;
      background-color: black;

      .background-image {
        width: 100%;
        
    
        height: 100%;
        opacity: 100%;
        // border-radius: 10px;
        // position: relative;
        // border-style: solid;
        // opacity: 85%;
        filter: contrast(120%);
        
  
        &.Magic {
          scale: 1;
          background-size: cover;
          background-position: top;
        }
  
        &.LeBron {
          background-size: cover;
          background-position: top;
        }
  
        &.Steph {
          background-size: cover;
          background-position: top;
        }
  
      }

    }

  }
  