.navigation {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: black;
    overflow: hidden;
  
    .logo-container {
      height: 100%;
      width: 70px;
      padding: 25px;

      .logo:hover{
        transform: scale(1.1);
        transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }

    }
  
    .nav-links-container {
      width: inherit;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .glossary {
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
          transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        }

      }

      .support-me {
        padding-right: 7vw;
        padding-left: 5vw;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
          transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        }
      }

      a {
        font-size: 1.25rem;
        color: #FCCB06;
      }
    }
  }
  