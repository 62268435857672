.introduction {
    width: 100%;
    padding-top: 3vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: black;

    .image-container {
        min-width: 30%;
        flex: 1 1 auto;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-right: 10vw;
        overflow: hidden;
        

        .kobe-image {
            flex: 1.53153153153;
            height: 333px;
            display: block;
            float: left;
            overflow: hidden;
        }
    }

    .how-to {
        padding-bottom: 8.125rem;
        margin-left: 10vw;
        max-width: 80%;

        h1 {
            font-size: 70px;
        }

        h2 {
            padding-top: 0;

        }

        p {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 25px;
            color: #b1ddf1;
        }
    }

    
}