@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

body {
  margin: 0;
  /* font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

code {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

* {
  box-sizing: border-box;
}


a, h1, h2, h3, p {
  text-decoration: none;
  color: white;
  font-family: 'Open Sans', sans-serif;
}  
